import React from "react";
import { Loader } from "components/commons";
import styles from "./shift-details-stock-inventory.module.scss";
import ShiftDetailsFuelSales from "./shift-details-stock-inventory-fuel-sales";
import ShiftDetailsAllFuelProducts from "./shift-details-stock-inventory-all-fuel-product";
import ShiftDetailsFuelDeliveries from "./shift-details-stock-inventory-fuel-deliveries";
import ShiftDetailsFuelVariance from "./shift-details-stock-inventory-fuel-variance";

const ShiftRecordsDetailsStockInventoryWetModule = (props) => {
  const {
    loadingShift,
    shift,
    form,
    beginDidNotPerformDipStick,
    setBeginDidNotPerformDipStick,
    endDidNotPerformDipStick,
    setEndDidNotPerformDipStick,
    progress,
    pageMode,
    isShiftEndedCompletely,
  } = props;

  return (
    <>
      <Loader open={loadingShift} />
      {shift && !loadingShift && (
        <div className={styles.container}>
          <ShiftDetailsFuelSales
            isShiftEndedCompletely={isShiftEndedCompletely}
            shift={shift}
            progress={progress}
            {...props}
            form={form}
            beginDidNotPerformDipStick={beginDidNotPerformDipStick}
            setBeginDidNotPerformDipStick={setBeginDidNotPerformDipStick}
            endDidNotPerformDipStick={endDidNotPerformDipStick}
            setEndDidNotPerformDipStick={setEndDidNotPerformDipStick}
          />
          <ShiftDetailsAllFuelProducts
            isShiftEndedCompletely={isShiftEndedCompletely}
            shift={shift}
          />
          <ShiftDetailsFuelDeliveries shift={shift} />
          <ShiftDetailsFuelVariance
            shift={shift}
            progress={progress}
            form={form}
            pageMode={pageMode}
          />
        </div>
      )}
    </>
  );
};

export default ShiftRecordsDetailsStockInventoryWetModule;
