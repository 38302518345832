import styles from "./shift-details-stock-inventory.module.scss";
import { allFuelProductsMapper } from "./shift-details-stock-inventory.mapper";
import { allFuelProductsColumn } from "./shift-details-stock-inventory-column";
import { locale } from "localization/en";
import { initialFilterState } from "modules/shift-records/shift-records-filter.state";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import useDataTable from "hooks/useDataTable";

const ShiftDetailsAllFuelProducts = ({ isShiftEndedCompletely, shift }) => {
  const formatShiftValue = ({ data }) => {
    let list = [];
    list.push(data);
    return list;
  };

  const { table } = useDataTable({
    value: formatShiftValue({ data: shift?.shift }),
    filter: { initialState: initialFilterState(), mapper: (val) => val },
    table: {
      key: "shift",
      name: "allFuel",
      mapper: (val) => allFuelProductsMapper({ val, isShiftEndedCompletely }),
      columns: allFuelProductsColumn,
    },
  });

  return (
    <>
      <div className={styles.tableContainer}>
        <DataTableV2
          {...table}
          {...{ name: locale.allFuelProducts }}
          hideNoSearchResultRender
          tableVersion="v2"
          hidePagination
        />
      </div>
      <div className={styles.spacer50} />
    </>
  );
};

export default ShiftDetailsAllFuelProducts;
