import { Text } from "components/commons";
import { formatAmount, formatVolume } from "utils";
import styles from "./shift-details-stock-inventory.module.scss";
import { dateFormat, timeFormat } from "utils";
import { prettifyProductCode, prettifyEmployeePosition } from "utils/pretty.utils";
import Big from "big.js";
import { filter } from "lodash";
import { EmployeePosition } from "enums";
import classNames from "classnames";

export const fuelSalesMapper = ({ val }) => {
  const {
    metadata,
    originalPricePerItem,
    pumpDiscountPerItem,
    quantity,
    totalTransactionDiscount,
    lastTotalInventory,
    beginningTotalizer,
    netTotalAmount,
    totalizerDifference,
    totalizerVariance,
    grossTotalAmount,
  } = val;
  let pumpNumber;
  const metadataObj = metadata ? JSON.parse(metadata) : null;
  const totalizerVariancePercentage =
    totalizerDifference && totalizerDifference !== 0
      ? Number(Big(totalizerVariance).div(totalizerDifference).mul(100)).toFixed(2)
      : 0;

  if (metadataObj) {
    pumpNumber = metadataObj.split(",")[0].slice(-1);
  }

  return {
    pumpNumber: (
      <>
        <Text className={styles.content}>{pumpNumber ?? "--"}</Text>
      </>
    ),
    pumpPrice: (
      <>
        <Text className={styles.content}>{formatAmount(originalPricePerItem ?? 0)}</Text>
      </>
    ),
    pumpDiscount: (
      <>
        <Text className={styles.content}>{formatAmount(pumpDiscountPerItem ?? 0)}</Text>
      </>
    ),
    beginningTotalizer: (
      <>
        <Text className={styles.content}>{formatVolume(beginningTotalizer ?? 0)}</Text>
      </>
    ),
    endingTotalizer: (
      <>
        <Text className={styles.content}>{formatVolume(lastTotalInventory ?? 0)}</Text>
      </>
    ),
    salesInLiters: (
      <>
        <Text className={styles.content}>{formatVolume(quantity ?? 0)}</Text>
      </>
    ),
    amount: (
      <>
        <Text className={styles.content}>{formatAmount(grossTotalAmount ?? 0)}</Text>
      </>
    ),
    otherDiscount: (
      <>
        <Text className={styles.content}>{formatAmount(totalTransactionDiscount ?? 0)}</Text>
      </>
    ),
    finalAmount: (
      <>
        <Text className={styles.content}>{formatAmount(netTotalAmount ?? 0)}</Text>
      </>
    ),
    totalizerDifference: (
      <>
        <Text className={styles.content}>{formatVolume(totalizerDifference ?? 0)}</Text>
      </>
    ),
    totalizerVariance: (
      <>
        <Text
          className={classNames(
            styles.content,
            totalizerVariance > 0 && styles.positive,
            totalizerVariance < 0 && styles.negative
          )}
        >{`${formatVolume(totalizerVariance ?? 0)} (${totalizerVariancePercentage}%)`}</Text>
      </>
    ),
  };
};

export const allFuelProductsMapper = ({ val, isShiftEndedCompletely }) => {
  const {
    totalLitersSold,
    totalDiscounts,
    totalAmountFuelSales,
    totalOriginalAmountFuelSales,
    totalTotalizerDifference,
  } = val;

  const totalTotalizerVariance = Number(Big(totalLitersSold).minus(totalTotalizerDifference));
  const totalizerVariancePercentage =
    totalTotalizerDifference && totalTotalizerDifference !== 0
      ? Number(Big(totalTotalizerVariance).div(totalTotalizerDifference).mul(100)).toFixed(2)
      : 0;

  return {
    salesInLiters: (
      <>
        <Text className={styles.content}>{formatVolume(totalLitersSold ?? 0)}</Text>
      </>
    ),
    amount: (
      <>
        <Text className={styles.content}>{formatAmount(totalOriginalAmountFuelSales ?? 0)}</Text>
      </>
    ),
    otherDiscount: (
      <>
        <Text className={styles.content}>{formatAmount(totalDiscounts ?? 0)}</Text>
      </>
    ),
    finalAmount: (
      <>
        <Text className={styles.content}>{formatAmount(totalAmountFuelSales ?? 0)}</Text>
      </>
    ),
    totalizerDifference: (
      <>
        <Text className={styles.content}>
          {isShiftEndedCompletely ? formatVolume(totalTotalizerDifference ?? 0) : "N/A"}
        </Text>
      </>
    ),
    totalizerVariance: (
      <>
        <Text
          className={classNames(
            styles.content,
            isShiftEndedCompletely && totalTotalizerVariance > 0 && styles.positive,
            isShiftEndedCompletely && totalTotalizerVariance < 0 && styles.negative
          )}
        >
          {isShiftEndedCompletely
            ? `${formatVolume(totalTotalizerVariance ?? 0)} (${totalizerVariancePercentage}%)`
            : "N/A"}
        </Text>
      </>
    ),
  };
};

export const fuelDeliveriesMapper = ({ val }) => {
  const { deliveryId, deliveredAt, poReferenceNumber, plateNumber, witnesses } = val;
  let witness = [];
  if (witnesses && witnesses.length > 0) {
    filter(witnesses, (e) => {
      const cashier = e.position === prettifyEmployeePosition(EmployeePosition.CASHIER);
      if (cashier) {
        witness.push(e.name);
      }
      return cashier;
    });
    filter(witnesses, (e) => {
      const crew = e.position !== prettifyEmployeePosition(EmployeePosition.CASHIER);
      if (crew) {
        witness.push(e.name);
      }
      return crew;
    });
  }

  return {
    deliveryId: (
      <>
        <Text className={styles.content}>{deliveryId ?? "0"}</Text>
      </>
    ),
    dateAndTime: (
      <>
        <Text className={styles.content}>{`${dateFormat(deliveredAt)} ${timeFormat(
          deliveredAt
        )}`}</Text>
      </>
    ),
    poReferenceNumber: (
      <>
        <Text className={styles.content}>{poReferenceNumber ?? ""}</Text>
      </>
    ),
    plateNumber: (
      <>
        <Text className={styles.content}>{plateNumber ?? ""}</Text>
      </>
    ),
    witnesses: (
      <>
        {witness.map((item, i) => (
          <Text className={styles.content}>{item ?? ""}</Text>
        ))}
      </>
    ),
  };
};

export const fuelDeliveryProductsMapper = ({ val }) => {
  const {
    deliveryId,
    product,
    poReferenceNumber,
    quantityDeliveredL,
    quantityAfterReceivingCm,
    quantityAfterReceivingL,
    quantityBeforeReceivingCm,
    quantityBeforeReceivingL,
  } = val;

  const quantityL = Number(Big(quantityAfterReceivingL).minus(quantityBeforeReceivingL));
  const variance = Number(Big(quantityL).minus(quantityDeliveredL));
  const quantity = Number(Big(quantityBeforeReceivingL).add(quantityDeliveredL));
  let variancePercentage = Number(Big(variance).div(quantity).mul(100));

  if (variancePercentage > 0) {
    variancePercentage = `(+${variancePercentage.toFixed(2)}%)`;
  } else {
    variancePercentage = `(${variancePercentage.toFixed(2)}%)`;
  }

  return {
    deliveryId: (
      <>
        <Text className={styles.content}>{deliveryId ?? "0"}</Text>
      </>
    ),
    poReferenceNumber: (
      <>
        <Text className={styles.content}>{poReferenceNumber ?? ""}</Text>
      </>
    ),
    product: (
      <>
        <Text className={styles.content}>{prettifyProductCode(product) ?? ""}</Text>
      </>
    ),
    beforeReceivingCm: (
      <>
        <Text className={styles.content}>{formatVolume(quantityBeforeReceivingCm, "") ?? 0}</Text>
      </>
    ),
    beforeReceiving: (
      <>
        <Text className={styles.content}>{formatVolume(quantityBeforeReceivingL) ?? ""}</Text>
      </>
    ),
    quantityDelivered: (
      <>
        <Text className={styles.content}>{formatVolume(quantityDeliveredL) ?? ""}</Text>
      </>
    ),
    afterReceivingCm: (
      <>
        <Text className={styles.content}>{formatVolume(quantityAfterReceivingCm, "") ?? ""}</Text>
      </>
    ),
    afterReceiving: (
      <>
        <Text className={styles.content}>{formatVolume(quantityAfterReceivingL) ?? ""}</Text>
      </>
    ),
    variance: (
      <>
        <Text
          className={
            variance > 0 ? styles.positive : variance < 0 ? styles.negative : styles.content
          }
        >
          {variance > 0
            ? `+${formatVolume(variance)} ${variancePercentage}`
            : variance < 0
            ? `${formatVolume(variance)} ${variancePercentage}`
            : formatVolume(variance)}
        </Text>
      </>
    ),
  };
};

export const fuelVarianceMapper = ({ val }) => {
  const { ugt, diesel, gas91, gas95, gas97, dieselStyle, gas91Style, gas95Style, gas97Style } = val;
  return {
    ugt: (
      <>
        <Text className={styles.content}>{ugt ?? ""}</Text>
      </>
    ),
    diesel: (
      <>
        <Text className={classNames(dieselStyle ? dieselStyle : styles.content)}>
          {diesel ?? ""}
        </Text>
      </>
    ),
    gas91: (
      <>
        <Text className={classNames(gas91Style ? gas91Style : styles.content)}>{gas91 ?? ""}</Text>
      </>
    ),
    gas95: (
      <>
        <Text className={classNames(gas95Style ? gas95Style : styles.content)}>{gas95 ?? ""}</Text>
      </>
    ),
    gas97: (
      <>
        <Text className={classNames(gas97Style ? gas97Style : styles.content)}>{gas97 ?? ""}</Text>
      </>
    ),
  };
};

export const dryStockSalesMapper = ({ val }) => {
  const {
    name,
    variant,
    originalPricePerItem,
    totalDiscount,
    quantity,
    originalTotalAmount,
    netTotalAmount,
  } = val;
  return {
    product: (
      <>
        <Text className={styles.content}>{name ?? ""}</Text>
      </>
    ),
    variant: (
      <>
        <Text className={styles.content}>{variant ?? ""}</Text>
      </>
    ),
    unitPrice: (
      <>
        <Text className={styles.content}>{formatAmount(originalPricePerItem ?? 0)}</Text>
      </>
    ),
    quantitySold: (
      <>
        <Text className={styles.content}>{quantity ?? 0}</Text>
      </>
    ),
    amount: (
      <>
        <Text className={styles.content}>{formatAmount(originalTotalAmount ?? 0)}</Text>
      </>
    ),
    discounts: (
      <>
        <Text className={styles.content}>{formatAmount(totalDiscount ?? 0)}</Text>
      </>
    ),
    finalAmount: (
      <>
        <Text className={styles.content}>{formatAmount(netTotalAmount ?? 0)}</Text>
      </>
    ),
  };
};
