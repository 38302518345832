import locale from "localization/en";

export const fuelSalesColumn = [
  { key: "pumpNumber", text: locale.pumpNo },
  { key: "pumpPrice", text: locale.pumpPrice },
  { key: "pumpDiscount", text: locale.pumpDiscount },
  { key: "beginningTotalizer", text: locale.beginningTotalizer },
  { key: "endingTotalizer", text: locale.endingTotalizer },
  { key: "salesInLiters", text: locale.recordedVolume },
  { key: "amount", text: locale.amount },
  { key: "otherDiscount", text: locale.otherDiscounts },
  { key: "finalAmount", text: locale.finalAmount },
  { key: "totalizerDifference", text: locale.totalizerDifference },
  { key: "totalizerVariance", text: locale.totalizerVariance },
];

export const allFuelProductsColumn = [
  { key: "salesInLiters", text: locale.recordedVolume },
  { key: "amount", text: locale.amount },
  { key: "otherDiscount", text: locale.otherDiscounts },
  { key: "finalAmount", text: locale.finalAmount },
  { key: "totalizerDifference", text: locale.totalizerDifference },
  { key: "totalizerVariance", text: locale.totalizerVariance },
];

export const fuelDeliveriesColumn = [
  { key: "deliveryId", text: locale.deliveryId },
  { key: "dateAndTime", text: locale.dateAndTime },
  { key: "poReferenceNumber", text: locale.poReferenceNumber },
  { key: "plateNumber", text: locale.plateNumber },
  { key: "witnesses", text: locale.witnesses },
];

export const fuelDeliveryProductsColumn = [
  { key: "deliveryId", text: locale.deliveryId },
  { key: "poReferenceNumber", text: locale.poReferenceNumber },
  { key: "product", text: locale.product },
  { key: "beforeReceivingCm", text: locale.beforeReceivingCm },
  { key: "beforeReceiving", text: locale.beforeReceiving },
  { key: "quantityDelivered", text: locale.quantityDelivered },
  { key: "afterReceivingCm", text: locale.afterReceivingCm },
  { key: "afterReceiving", text: locale.afterReceiving },
  { key: "variance", text: locale.variance, width: "200px" },
];

export const fuelVarianceColumn = [
  { key: "ugt", text: locale.ugt },
  { key: "diesel", text: locale.diesel },
  { key: "gas91", text: locale.gas91 },
  { key: "gas95", text: locale.gas95 },
  { key: "gas97", text: locale.gas97 },
];

export const dryStockSalesColumn = [
  { key: "product", text: locale.product },
  { key: "variant", text: locale.variant },
  { key: "unitPrice", text: locale.unitPrice },
  { key: "quantitySold", text: locale.quantitySold },
  { key: "amount", text: locale.amount },
  { key: "discounts", text: locale.discounts },
  { key: "finalAmount", text: locale.finalAmount },
];

export const dryStockDeliveriesColumn = [
  { key: "deliveryId", text: locale.deliveryId },
  { key: "dateAndTime", text: locale.dateAndTime },
  { key: "poReferenceNumber", text: locale.poReferenceNumber },
  { key: "plateNumber", text: locale.plateNumber },
  { key: "witnesses", text: locale.witnesses },
];
export const dryStockDeliveryProductsColumn = [
  { key: "deliveryId", text: locale.deliveryId },
  { key: "poReferenceNumber", text: locale.poReferenceNumber },
  { key: "product", text: locale.product },
  { key: "beforeReceiving", text: locale.beforeReceivingDS },
  { key: "quantityDelivered", text: locale.quantityDelivered },
  { key: "afterReceiving", text: locale.afterReceivingDS },
  { key: "variance", text: locale.variance, width: "200px" },
];

export const dryStockMovementColumn = [
  {
    key: "product",
    text: locale.product,
  },
  {
    key: "actualBeginningInventory",
    text: locale.actualBeginningInvetory,
  },
  {
    key: "delivery",
    text: locale.delivery,
  },
  {
    key: "sales",
    text: locale.sales,
  },
  {
    key: "returns",
    text: locale.returns,
  },
  {
    key: "expectedEndingInvetory",
    text: locale.expectedEndingInventory,
  },
  {
    key: "actualEndingInventory",
    text: locale.actualBeginningInventory,
  },
  {
    key: "variance",
    text: locale.variance,
  },
];
