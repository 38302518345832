import locale from "localization/en";

const PumpMethodEnum = {
  error: locale.pumpError,
  calibration: locale.pumpCalibration,
  return: locale.return,
  refund: locale.refund,
};

export default PumpMethodEnum;
