import React from "react";
import locale from "localization/en";
import styles from "./shift-records-details-cash-and-non-cash.module.scss";
import { Title, Image, Dropdown, Field, Button, Text } from "components/commons";
import { FormMode } from "enums";
import { RemoveIcon } from "images";
import { useMount } from "hooks";
import { Icon } from "@material-ui/core";
import { formatAmount } from "utils";
import { sumBy, find } from "lodash";
import Big from "big.js";
import { InputAmount } from "components/field";
import grid from "styles/grid.module.scss";
import { ShiftStatus } from "enums";

const ShiftDetailsNetCashSummary = ({
  shift,
  pageMode,
  employees,
  setEmployees,
  safeDrop,
  employeesToPay,
  setEmployeesToPay,
  variance,
  setVariance,
}) => {
  const { expense } = shift;
  const status = shift?.shift.status;
  const totalExpenses = sumBy(expense, "amount") || 0;
  const { beginningCashBalance, cashSales } = shift.shift;

  const totalSafeDrop = sumBy(safeDrop, "totalAmount") || 0;

  const cashOnHand = Number(Big(beginningCashBalance).add(cashSales).minus(totalExpenses));
  setVariance(Number(Big(totalSafeDrop).minus(cashOnHand)));

  const formatNetCash = ({ data }) => {
    return [
      {
        label: locale.beginningBalance,
        value:
          (beginningCashBalance > 0 ? "+ " : beginningCashBalance < 0 ? "- ₱" : "") +
          formatAmount(beginningCashBalance),
      },
      {
        label: locale.cashSales,
        value: (cashSales > 0 ? "+ " : cashSales < 0 ? "- ₱" : "") + formatAmount(cashSales, ""),
      },
      {
        label: locale.expenses,
        value:
          (totalExpenses > 0 ? "- " : totalExpenses < 0 ? "₱" : "") + formatAmount(totalExpenses),
      },
      {
        label: locale.expectedCashOnHand,
        value: formatAmount(cashOnHand),
      },
      {
        label: locale.actualCashOnHand,
        value: formatAmount(totalSafeDrop),
      },
      {
        label: locale.variance,
        value:
          (variance > 0 ? "+ ₱" : variance < 0 ? "- ₱" : "") + formatAmount(Math.abs(variance), ""),
      },
    ];
  };

  const netCashSummaryData = formatNetCash({ data: shift?.shift });

  const addRow = () => {
    setEmployeesToPay([
      ...employeesToPay,
      {
        label: "",
        value: "",
        shoulderAmount: 0,
        amountCheck: false,
      },
    ]);
  };

  const onRemove = (i) => {
    const newForm = [...employeesToPay];
    newForm.splice(i, 1);
    setEmployeesToPay(newForm);
  };

  const tempList = [...employees];
  employees.forEach((a, i) => {
    if (a.name === "" || a.agency === "" || a.label === "") {
      tempList.splice(i, 1);
    }
  });

  const options = tempList.map((employee) => ({
    value: employee.name,
    label: employee.name,
  }));

  const onChangeAmount = (value, i) => {
    const newForm = [...employeesToPay];
    let inValid = true;
    newForm[i]["shoulderAmount"] = value;

    if (value.length > 0) {
      inValid = false;
    }
    newForm[i].amountCheck = inValid;

    setEmployeesToPay(newForm);
  };

  const onChangeName = (value, i) => {
    const newForm = [...employeesToPay];
    let inValid = false;
    if (find(employeesToPay, (ep) => ep.value === value)) {
      inValid = true;
    } else {
      const obj = value;
      newForm[i]["label"] = obj.name;
      newForm[i]["value"] = value;
    }

    newForm[i].nameCheck = inValid;

    setEmployeesToPay(newForm);
  };

  useMount(() => {
    if (Big(employeesToPay.length).eq(0)) {
      setEmployeesToPay([]);
      const newEmployeesToPay = [];
      employees.forEach((i) => {
        if (Big(i.shoulderAmount || 0).gt(0)) {
          newEmployeesToPay.push({
            label: i.name,
            value: i,
            shoulderAmount: i.shoulderAmount,
            nameCheck: false,
            amountCheck: false,
          });
        }
      });
      setEmployeesToPay(newEmployeesToPay);
    }
  });

  return (
    <>
      <Title small black>
        {locale.netCashSummary}
      </Title>
      <div className={styles.spacer10} />
      <div className={styles.netCashTable}>
        {netCashSummaryData && netCashSummaryData.length > 0 && (
          <table className={styles.table}>
            <tbody>
              {netCashSummaryData.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Text className={styles.label}>{item.label ?? ""}</Text>
                    </td>

                    <td>
                      <Text
                        className={
                          item.label === locale.variance
                            ? variance > 0
                              ? styles.positive
                              : variance < 0
                              ? styles.negative
                              : styles.content
                            : styles.content
                        }
                      >
                        {item.value ?? "0"}
                      </Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>

      {pageMode === FormMode.View && (
        <>
          <div className={styles.spacer20} />
          {status !== ShiftStatus.Started && variance < 0 && (
            <div className={styles.container}>
              <div className={grid.row}>
                <div className={grid.column}>
                  <Text className={styles.label}>{locale.employeeToShoulderShortage}</Text>
                </div>
                <div className={grid.column}>
                  <Text className={styles.label}>{locale.amountToShoulder}</Text>
                </div>
              </div>
            </div>
          )}
          {status !== ShiftStatus.Started &&
            variance < 0 &&
            employees.map(
              (item, i) =>
                item.shoulderAmount !== 0 && (
                  <div className={styles.container}>
                    <div className={grid.row}>
                      <div className={grid.column}>
                        <Text className={styles.content}>{item.name}</Text>
                      </div>
                      <div className={grid.column}>
                        <Text className={styles.content}>{formatAmount(item.shoulderAmount)}</Text>
                      </div>
                    </div>
                  </div>
                )
            )}
        </>
      )}

      {Big(variance).lt(0) && pageMode === FormMode.Edit ? (
        <table className={styles.tableView}>
          <tbody>
            {employeesToPay.map((item, i) => (
              <tr key={i}>
                <td style={{ paddingRight: "20px" }}>
                  {options.length > 0 && (
                    <Field className={styles.content} label={locale.employeeToShoulderShortage}>
                      <Dropdown
                        error={item.nameCheck}
                        onChange={(value) => {
                          onChangeName(value, i);
                        }}
                        value={item.value}
                        options={options}
                      />
                    </Field>
                  )}
                </td>
                <td className={styles.addOrEditTd}>
                  <Field className={styles.content} label={locale.amountToShoulder}>
                    <InputAmount
                      innerLabel
                      error={item.amountCheck}
                      label={locale.amount}
                      value={item.shoulderAmount}
                      onChange={(_, { value }) => {
                        onChangeAmount(value, i);
                      }}
                    />
                  </Field>
                </td>
                <td>
                  <div>
                    {i === 0 ? (
                      ""
                    ) : (
                      <span onClick={() => onRemove(i)} className={styles.remove}>
                        <Image src={RemoveIcon} />
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          <div className={styles.spacer10} />
          <Button
            outline
            onClick={addRow}
            startIcon={Icon.Add}
            disabled={options.length === employeesToPay.length}
          >
            {locale.addEmployee}
          </Button>
        </table>
      ) : (
        <></>
      )}
      <div className={styles.spacer50} />
    </>
  );
};

export default ShiftDetailsNetCashSummary;
