import locale from "localization/en";

export const nonSaleTransactionColumn = [
  { key: "pump", text: locale.pump, width: "9%" },
  { key: "product", text: locale.product },
  { key: "transactionType", text: locale.transactionType, width: "15%" },
  { key: "referenceNo", text: locale.referenceNo },
  { key: "quantity", text: locale.quantity },
  { key: "amount", text: locale.amount },
  { key: "transactionId", text: locale.transactionNo },
  {
    key: "refName",
    text: (
      <span>
        {locale.refName} /<br />
        {locale.plateNumber}
      </span>
    ),
  },
  { key: "remarks", text: locale.remarks },
];

export const nonCashTransactionColumn = [
  {
    key: "paymentMethod",
    text: (
      <span>
        {locale.paymentMethod} /<br />
        {locale.referenceNo}
      </span>
    ),
  },
  { key: "amount", text: locale.amount },
  { key: "discount", text: `${locale.discount}/${locale.premium}` },
  { key: "finalAmount", text: locale.finalAmountPaid },
  { key: "transactionId", text: locale.salesInvoiceNo },
  { key: "product", text: locale.product },
  { key: "pumpNumber", text: locale.pumpNumber },
  { key: "quantity", text: locale.quantity },
  {
    key: "refName",
    text: (
      <span>
        {locale.refName} /<br />
        {locale.plateNumber}
      </span>
    ),
  },
  { key: "remarks", text: locale.remarks },
];

export const cashTransactionColumn = [
  { key: "product", text: locale.product },
  { key: "pumpNumber", text: locale.pumpNumber },
  { key: "quantity", text: locale.quantity },
  { key: "amount", text: locale.amount },
];

export const expensesColumn = [
  { key: "expenseId", text: locale.expenseId },
  { key: "type", text: locale.typeOfExpense },
  { key: "dateAndTime", text: locale.dateAndTime },
  { key: "amount", text: locale.amount },
  { key: "remarks", text: locale.remarks },
];

export const cashOnHandColumn = [
  { key: "denomination", text: locale.denomination },
  { key: "safeDrops", text: locale.safeDrops },
  { key: "endShift", text: locale.endOfShift },
  { key: "total", text: locale.total },
  { key: "totalAmount", text: locale.totalAmount },
];

export const netCashSummaryColumn = [
  { key: "label", text: "" },
  { key: "value", text: "" },
];

export const bankDepositColumn = [
  { key: "depositId", text: locale.depositId },
  { key: "dateAndTime", text: locale.dateAndTime },
  { key: "amount", text: locale.amount },
  { key: "depositType", text: locale.depositType },
  { key: "remarks", text: locale.remarks },
];

export const keyAccountCollectionColumn = [
  { key: "collectionId", text: locale.collectionId },
  { key: "dateTime", text: locale.collectionDateAndTime },
  { key: "amount", text: locale.amount },
  { key: "siNumSoa", text: locale.siNumSoa },
  { key: "keyAccountName", text: locale.keyAccountName },
  { key: "paymentMethod", text: locale.paymentMethod },
  { key: "remarks", text: locale.remarks },
];
