import React from "react";
import { Loader } from "components/commons";
import styles from "./shift-details-stock-inventory.module.scss";
import ShiftDetailsDryStockSalesLubricant from "./shift-details-stock-inventory-dry-stock-sales-lubricant";
import ShiftDetailsDryStockSalesLpg from "./shift-details-stock-inventory-dry-stock-sales-lpg";
import ShiftRecordDryStockMovement from "./shift-records-dry-stock-inventory-movement";
import ShiftDetailsDryStockDeliveries from "./shift-records-dry-stock-inventory-deliveries";

const ShiftRecordsDetailsStockInventoryDryModule = (props) => {
  const { loadingShift, shift, form } = props;

  return (
    <>
      <Loader open={loadingShift} />
      {shift && !loadingShift && (
        <div className={styles.container}>
          <ShiftRecordDryStockMovement shift={shift} form={form} />
          <ShiftDetailsDryStockDeliveries shift={shift} />
          <ShiftDetailsDryStockSalesLubricant shift={shift} />
          <ShiftDetailsDryStockSalesLpg shift={shift} />
        </div>
      )}
    </>
  );
};

export default ShiftRecordsDetailsStockInventoryDryModule;
