import locale from "localization";
import grid from "styles/grid.module.scss";
import ProductCode from "enums/product-code";
import { Text, Checkbox } from "components/commons";
import styles from "./shift-details-stock-inventory.module.scss";
import DipstickDetails from "./shift-details-stock-inventory-dipstick-details.module";
import DipstickInput from "../shift-details-stock-inventory-dipstick-input";
import React, { useCallback } from "react";
import classNames from "classnames";
import { FormMode } from "enums";
import Validation from "utils/validation.utils";
import { validateField } from "hooks/useForm";
import { formatNumber, formatVolume } from "utils";

export const validateReadingDipstick = (name, value, fields, fromCallback, shift) => {
  let data = {
    [name]: {
      value,
    },
  };

  const dipsticks = ["isReadingDipstickBeginning", "isReadingDipstickEnding"];

  const getDipstickFields = [
    "beginningDiesel",
    "beginningGas91",
    "beginningGas95",
    "beginningGas97",
    "beginningDieselCm",
    "beginningGas91Cm",
    "beginningGas95Cm",
    "beginningGas97Cm",
    "endingDiesel",
    "endingGas91",
    "endingGas95",
    "endingGas97",
    "endingDieselCm",
    "endingGas91Cm",
    "endingGas95Cm",
    "endingGas97Cm",
  ];

  const type = name === dipsticks[0] ? "beginning" : "ending";
  const isDidNotPerform = value;

  getDipstickFields.forEach((key) => {
    if (key.includes(type)) {
      var fieldValue = fromCallback && isDidNotPerform ? formatVolume(shift[key], "") : "";

      let dipstickField = validateField(
        {
          ...fields[key],
          disabled: isDidNotPerform,
          value: fieldValue,
          max: key.includes("Cm") ? 600 : 50000,
          validations:
            isDidNotPerform || !fields[key].required
              ? []
              : [
                  Validation.required({ noMessage: true }),
                  key.includes("Cm")
                    ? Validation.min(0, {
                        isFormatted: true,
                        customMessage: (
                          <locale.Populate items={["0.000"]} text={locale.valueShouldNotBe} />
                        ),
                      })
                    : Validation.min(0.001, {
                        isFormatted: true,
                        customMessage: (
                          <locale.Populate items={["0.000"]} text={locale.valueShouldNotBe} />
                        ),
                      }),
                  fields[key]?.maxLiter
                    ? Validation.max(fields[key]?.maxLiter, {
                        isFormatted: true,
                        customMessage: (
                          <locale.Populate
                            items={[formatNumber(fields[key]?.maxLiter, 3)]}
                            text={locale.maxLiterShouldBe}
                          />
                        ),
                      })
                    : Validation.max(key.includes("Cm") ? 600 : 50000, {
                        isFormatted: true,
                        customMessage: (
                          <locale.Populate
                            items={[key.includes("Cm") ? 600 : 50000]}
                            text={locale.maxLiterShouldBe}
                          />
                        ),
                      }),
                ],
        },
        fields
      );

      data = {
        ...data,
        [key]: dipstickField,
      };
    }
  });

  return data;
};

const ShiftDetailsDipstick = ({
  shift,
  progress,
  pageMode,
  form,
  beginDidNotPerformDipStick,
  setBeginDidNotPerformDipStick,
  endDidNotPerformDipStick,
  setEndDidNotPerformDipStick,
}) => {
  const { fields, modifyField, modifyForm } = form;
  const stationProducts = shift.station.stationUgt.map((item) => item.productCode);

  const isDidNotPerform = useCallback(
    (name, value) => {
      const validatedFields = validateReadingDipstick(name, value, fields, true, shift.recentShift);
      let data = {
        [name]: {
          value,
        },
        ...validatedFields,
      };

      if (name?.toLowerCase()?.includes("beginning")) {
        setBeginDidNotPerformDipStick(value);
      } else {
        setEndDidNotPerformDipStick(value);
      }

      modifyForm({
        ...fields,
        ...data,
      });

      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields]
  );

  return (
    <div className={styles.subContainer}>
      <div className={grid.row}>
        <div className={styles.column}>
          {pageMode === FormMode.View && (
            <>
              <Text className={styles.header}>{locale.beginningInventory}</Text>

              {!shift?.shift.isReadingDipstickBeginning && progress === 100.0 && (
                <>
                  <div className={styles.spacer10} />
                  <Text className={styles.content}>{locale.didNotPerformDipstickReading}</Text>
                </>
              )}

              {(shift?.shift.isReadingDipstickBeginning ||
                (progress !== 100.0 && shift?.shift.status === "ended")) && (
                <>
                  {stationProducts.includes("diesel") && (
                    <DipstickDetails
                      label={`${locale.ugt}1`}
                      progress={progress}
                      shift={shift}
                      product={ProductCode.DIESEL}
                    />
                  )}

                  {stationProducts.includes("gas91") && (
                    <DipstickDetails
                      label={`${locale.ugt}2`}
                      progress={progress}
                      shift={shift}
                      product={ProductCode.GAS91}
                    />
                  )}

                  {stationProducts.includes("gas95") && (
                    <DipstickDetails
                      label={`${locale.ugt}3`}
                      progress={progress}
                      shift={shift}
                      product={ProductCode.GAS95}
                    />
                  )}

                  {stationProducts.includes("gas97") && (
                    <DipstickDetails
                      label={`${locale.ugt}4`}
                      progress={progress}
                      shift={shift}
                      product={ProductCode.GAS97}
                    />
                  )}
                </>
              )}

              <div className={styles.spacer50} />
            </>
          )}
        </div>
        <div className={styles.column}>
          {pageMode === FormMode.View && (
            <>
              <Text className={styles.header}>{locale.endingInventory}</Text>
              {!shift?.shift.isReadingDipstickEnding && progress === 100.0 && (
                <>
                  <div className={styles.spacer10} />
                  <Text className={styles.content}>{locale.didNotPerformDipstickReading}</Text>
                </>
              )}
              {(shift?.shift.isReadingDipstickEnding ||
                (progress !== 100.0 && shift?.shift.status === "ended")) && (
                <>
                  {stationProducts.includes("diesel") && (
                    <DipstickDetails
                      label={`${locale.ugt}1`}
                      progress={progress}
                      shift={shift}
                      product={ProductCode.DIESEL}
                      isEndingDipstick={true}
                    />
                  )}

                  {stationProducts.includes("gas91") && (
                    <DipstickDetails
                      label={`${locale.ugt}2`}
                      progress={progress}
                      shift={shift}
                      product={ProductCode.GAS91}
                      isEndingDipstick={true}
                    />
                  )}
                  {stationProducts.includes("gas95") && (
                    <DipstickDetails
                      label={`${locale.ugt}3`}
                      progress={progress}
                      shift={shift}
                      product={ProductCode.GAS95}
                      isEndingDipstick={true}
                    />
                  )}

                  {stationProducts.includes("gas97") && (
                    <DipstickDetails
                      label={`${locale.ugt}4`}
                      progress={progress}
                      shift={shift}
                      product={ProductCode.GAS97}
                      isEndingDipstick={true}
                    />
                  )}
                </>
              )}

              <div className={styles.spacer50} />
            </>
          )}
        </div>
      </div>

      {pageMode === FormMode.Edit && (
        <div className={styles.editSalesContainer}>
          <div className={styles.beginningInventory}>
            <Text className={styles.header}>{locale.beginningInventory}</Text>
            <div className={styles.checkBox}>
              <Checkbox
                {...fields?.isReadingDipstickBeginning}
                onChange={(name, { value }) => {
                  isDidNotPerform(name, value);
                }}
              />
              {"Did not perform dipstick reading"}
            </div>

            {stationProducts.includes("diesel") && (
              <div className={styles.dipstick}>
                <Text className={classNames(styles.classLabel, styles[ProductCode.DIESEL])}>
                  {"UGT 1 (Diesel)"}
                </Text>
                <div className={styles.row}>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.beginningDieselCm}
                      unit={"cm"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={beginDidNotPerformDipStick}
                    />
                  </div>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.beginningDiesel}
                      unit={"L"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={beginDidNotPerformDipStick}
                    />
                  </div>
                </div>
              </div>
            )}

            {stationProducts.includes("gas91") && (
              <div className={styles.dipstick}>
                <Text className={classNames(styles.classLabel, styles[ProductCode.GAS91])}>
                  {"UGT 2 (Gas 91)"}
                </Text>
                <div className={styles.row}>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.beginningGas91Cm}
                      unit={"cm"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={beginDidNotPerformDipStick}
                    />
                  </div>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.beginningGas91}
                      unit={"L"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={beginDidNotPerformDipStick}
                    />
                  </div>
                </div>
              </div>
            )}

            {stationProducts.includes("gas95") && (
              <div className={styles.dipstick}>
                <Text className={classNames(styles.classLabel, styles[ProductCode.GAS95])}>
                  {"UGT 3 (Gas 95)"}
                </Text>
                <div className={styles.row}>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.beginningGas95Cm}
                      unit={"cm"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={beginDidNotPerformDipStick}
                    />
                  </div>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.beginningGas95}
                      unit={"L"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={beginDidNotPerformDipStick}
                    />
                  </div>
                </div>
              </div>
            )}

            {stationProducts.includes("gas97") && (
              <div className={styles.dipstick}>
                <Text className={classNames(styles.classLabel, styles[ProductCode.GAS97])}>
                  {"UGT 4 (Gas 97)"}
                </Text>
                <div className={styles.row}>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.beginningGas97Cm}
                      unit={"cm"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={beginDidNotPerformDipStick}
                    />
                  </div>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.beginningGas97}
                      unit={"L"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={beginDidNotPerformDipStick}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.endingInventory}>
            <Text className={styles.header}>{locale.endingInventory}</Text>
            <div className={styles.checkBox}>
              <Checkbox
                {...fields?.isReadingDipstickEnding}
                onChange={(name, { value }) => {
                  isDidNotPerform(name, value);
                }}
              />
              {"Did not perform dipstick reading"}
            </div>
            {stationProducts.includes("diesel") && (
              <div className={styles.dipstick}>
                <Text className={classNames(styles.classLabel, styles[ProductCode.DIESEL])}>
                  {"UGT 1 (Diesel)"}
                </Text>
                <div className={styles.row}>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.endingDieselCm}
                      unit={"cm"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={endDidNotPerformDipStick}
                    />
                  </div>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.endingDiesel}
                      unit={"L"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={endDidNotPerformDipStick}
                    />
                  </div>
                </div>
              </div>
            )}

            {stationProducts.includes("gas91") && (
              <div className={styles.dipstick}>
                <Text className={classNames(styles.classLabel, styles[ProductCode.GAS91])}>
                  {"UGT 2 (Gas 91)"}
                </Text>
                <div className={styles.row}>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.endingGas91Cm}
                      unit={"cm"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={endDidNotPerformDipStick}
                    />
                  </div>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.endingGas91}
                      unit={"L"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={endDidNotPerformDipStick}
                    />
                  </div>
                </div>
              </div>
            )}
            {stationProducts.includes("gas95") && (
              <div className={styles.dipstick}>
                <Text className={classNames(styles.classLabel, styles[ProductCode.GAS95])}>
                  {"UGT 3 (Gas 95)"}
                </Text>
                <div className={styles.row}>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.endingGas95Cm}
                      unit={"cm"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={endDidNotPerformDipStick}
                    />
                  </div>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.endingGas95}
                      unit={"L"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={endDidNotPerformDipStick}
                    />
                  </div>
                </div>
              </div>
            )}

            {stationProducts.includes("gas97") && (
              <div className={styles.dipstick}>
                <Text className={classNames(styles.classLabel, styles[ProductCode.GAS97])}>
                  {"UGT 4 (Gas 97)"}
                </Text>
                <div className={styles.row}>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.endingGas97Cm}
                      unit={"cm"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={endDidNotPerformDipStick}
                    />
                  </div>
                  <div className={styles.columnFluid}>
                    <DipstickInput
                      fields={fields?.endingGas97}
                      unit={"L"}
                      placeholder={"e.g 100.000"}
                      modifyField={modifyField}
                      disabled={endDidNotPerformDipStick}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ShiftDetailsDipstick;
