import { Title, DataTableV2 } from "components/commons";
import locale from "localization";
import styles from "./shift-details-stock-inventory.module.scss";
import useDataTable from "hooks/useDataTable";
import { dryStockMovementColumn } from "./shift-details-stock-inventory-column";
import { dryStockMovementMapper } from "./shift-details-stock-inventory.mapper";

const ShiftRecordDryStockMovement = ({ shift, form }) => {
  const fuelDeliveriesDataTable = useDataTable({
    value: shift?.delivery.deliveries,
    filter: { initialState: {}, mapper: (val) => val },
    table: {
      key: "movements",
      name: "dryStockMovements",
      mapper: (val) => dryStockMovementMapper({ val, form }),
      columns: dryStockMovementColumn,
    },
  });

  return (
    <>
      <Title small black>
        {locale.dryStockMovement}
      </Title>
      <div className={styles.spacer10} />
      <div className={styles.tableContainer}>
        <DataTableV2
          {...fuelDeliveriesDataTable.table}
          hideNoSearchResultRender
          tableVersion="v2"
          hidePagination
        />
      </div>
    </>
  );
};

export default ShiftRecordDryStockMovement;
