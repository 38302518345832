import { Text } from "components/commons";
import { dateFormat, formatAmount, formatVolume, timeFormat } from "utils";
import styles from "./shift-details-stock-inventory.module.scss";
import { InputAmount } from "components/field";
import { prettifyEmployeePosition, prettifyProductCode } from "utils/pretty.utils";
import Big from "big.js";
import { EmployeePosition } from "enums";
import { filter } from "lodash";

export const dryStockSalesMapper = ({ val }) => {
  const {
    name,
    variant,
    originalPricePerItem,
    totalDiscount,
    quantity,
    originalTotalAmount,
    netTotalAmount,
  } = val;
  return {
    product: (
      <>
        <Text className={styles.content}>{name ?? ""}</Text>
      </>
    ),
    variant: (
      <>
        <Text className={styles.content}>{variant ?? ""}</Text>
      </>
    ),
    unitPrice: (
      <>
        <Text className={styles.content}>{formatAmount(originalPricePerItem ?? 0)}</Text>
      </>
    ),
    quantitySold: (
      <>
        <Text className={styles.content}>{quantity ?? 0}</Text>
      </>
    ),
    amount: (
      <>
        <Text className={styles.content}>{formatAmount(originalTotalAmount ?? 0)}</Text>
      </>
    ),
    discounts: (
      <>
        <Text className={styles.content}>{formatAmount(totalDiscount ?? 0)}</Text>
      </>
    ),
    finalAmount: (
      <>
        <Text className={styles.content}>{formatAmount(netTotalAmount ?? 0)}</Text>
      </>
    ),
  };
};

export const dryStockDeliveriesMapper = ({ val }) => {
  const { deliveryId, deliveredAt, poReferenceNumber, plateNumber, witnesses } = val;
  let witness = [];
  if (witnesses && witnesses.length > 0) {
    filter(witnesses, (e) => {
      const cashier = e.position === prettifyEmployeePosition(EmployeePosition.CASHIER);
      if (cashier) {
        witness.push(e.name);
      }
      return cashier;
    });
    filter(witnesses, (e) => {
      const crew = e.position !== prettifyEmployeePosition(EmployeePosition.CASHIER);
      if (crew) {
        witness.push(e.name);
      }
      return crew;
    });
  }

  return {
    deliveryId: (
      <>
        <Text className={styles.content}>{deliveryId ?? "0"}</Text>
      </>
    ),
    dateAndTime: (
      <>
        <Text className={styles.content}>{`${dateFormat(deliveredAt)} ${timeFormat(
          deliveredAt
        )}`}</Text>
      </>
    ),
    poReferenceNumber: (
      <>
        <Text className={styles.content}>{poReferenceNumber ?? ""}</Text>
      </>
    ),
    plateNumber: (
      <>
        <Text className={styles.content}>{plateNumber ?? ""}</Text>
      </>
    ),
    witnesses: (
      <>
        {witness.map((item, i) => (
          <Text className={styles.content}>{item ?? ""}</Text>
        ))}
      </>
    ),
  };
};

export const dryStockDeliveryProductsMapper = ({ val }) => {
  const {
    deliveryId,
    product,
    poReferenceNumber,
    quantityDeliveredL,
    quantityAfterReceivingL,
    quantityBeforeReceivingL,
  } = val;

  const quantityL = Number(Big(quantityAfterReceivingL).minus(quantityBeforeReceivingL));
  const variance = Number(Big(quantityL).minus(quantityDeliveredL));
  const quantity = Number(Big(quantityBeforeReceivingL).add(quantityDeliveredL));
  let variancePercentage = Number(Big(variance).div(quantity).mul(100));

  if (variancePercentage > 0) {
    variancePercentage = `(+${variancePercentage.toFixed(2)}%)`;
  } else {
    variancePercentage = `(${variancePercentage.toFixed(2)}%)`;
  }

  return {
    deliveryId: (
      <>
        <Text className={styles.content}>{deliveryId ?? "0"}</Text>
      </>
    ),
    poReferenceNumber: (
      <>
        <Text className={styles.content}>{poReferenceNumber ?? ""}</Text>
      </>
    ),
    product: (
      <>
        <Text className={styles.content}>{prettifyProductCode(product) ?? ""}</Text>
      </>
    ),

    beforeReceiving: (
      <>
        <Text className={styles.content}>{formatVolume(quantityBeforeReceivingL) ?? ""}</Text>
      </>
    ),
    quantityDelivered: (
      <>
        <Text className={styles.content}>{formatVolume(quantityDeliveredL) ?? ""}</Text>
      </>
    ),
    afterReceiving: (
      <>
        <Text className={styles.content}>{formatVolume(quantityAfterReceivingL) ?? ""}</Text>
      </>
    ),
    variance: (
      <>
        <Text
          className={
            variance > 0 ? styles.positive : variance < 0 ? styles.negative : styles.content
          }
        >
          {variance > 0
            ? `+${formatVolume(variance)} ${variancePercentage}`
            : variance < 0
            ? `${formatVolume(variance)} ${variancePercentage}`
            : formatVolume(variance)}
        </Text>
      </>
    ),
  };
};

export const dryStockMovementMapper = ({ val, form }) => {
  const { product, delivery, sales, returns, expectedEndingInventory, variance } = val;

  const varianceColor = isNaN(variance)
    ? ""
    : variance === 0
    ? "black"
    : variance > 0
    ? "#459d4b"
    : "#f45047";

  return {
    product: (
      <>
        <Text>{product}</Text>
      </>
    ),
    actualBeginningInventory: (
      <>
        <InputAmount {...form?.fields?.actualBeginningInventory} onChange={form?.modifyField} />
      </>
    ),
    delivery: (
      <>
        <Text>{delivery}</Text>
      </>
    ),
    sales: (
      <>
        <Text>{sales}</Text>
      </>
    ),
    returns: (
      <>
        <Text>{returns}</Text>
      </>
    ),
    expectedEndingInventory: (
      <>
        <Text>{expectedEndingInventory}</Text>
      </>
    ),
    actualEndingInventory: (
      <>
        <InputAmount {...form?.fields?.actualEndingInventory} onChange={form?.modifyField} />
      </>
    ),
    variance: (
      <>
        <Text color={varianceColor}>{variance}</Text>
      </>
    ),
  };
};
